/* DataTable.css */

.plate{
    border: black 1px solid;
    padding: 0 5px;
    border-radius: 3px;
}

span.addButton{
    margin-top: -10px!important;
}