/* FormDemo.css */
#captcha > div > div{
    width:auto!important;
}
.form-holder .card {
    width: 100%;
    max-width: 450px;
    margin: auto;
    text-align: center;
}
.form-holder .card h1 {
    margin-top: -35px;
}
.form-holder .card .forgot-password {
    line-height: 45px;
    vertical-align: middle;
}
.p-float-label input:-webkit-autofill ~ label {
    top: -10px!important;
    font-size: 14px;
}
.form-holder .card form {
    margin-top: 2rem;
}
.form-holder .card .field,
.form-holder .card .field-checkbox {
    margin-bottom: 1.5rem;
}
@media screen and (max-width: 960px) {
    .form-holder .card {
        width: 80%;
    }
}
@media screen and (max-width: 640px) {
    .form-holder .card {
        width: 100%;
        min-width: 0;
    }
}

.wid10{width:10%}
.wid20{width:20%}
.wid30{width:30%}
.wid40{width:40%}
.wid45{width:45%}
.wid50{width:50%}
.wid60{width:60%}
.wid70{width:70%}
.wid80{width:80%}
.wid90{width:90%}
.wid100{width:100%}

.floatNone{float:none}
.floatLeft{float:left}
.floatRight{float:right}

.marginAuto{margin:auto}

.textDecorationNone{text-decoration: none;}